import {Button as AntButton} from 'antd';

export enum ButtonType {
  primary,
  bordered,
}

interface ButtonProps {
  text: string;
  onClick: () => void;
  type: ButtonType;
  leftImage?: string;
  rightImage?: string;
  className?: string;
  textSize?: string;
  isActive?: boolean;
  isLoading: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type,
  leftImage,
  rightImage,
  className,
  isLoading,
  isActive,
  textSize,
}) => {
  return (
    <AntButton
      onClick={onClick}
      loading={isLoading}
      disabled={isActive}
      className={`${
        type == ButtonType.primary ? 'bg-green' : 'bg-transparent'
      } rounded-md border border-green h-full px-[48px] py-[32px] ${className} flex items-center`}
    >
      {leftImage && <img src={leftImage}></img>}
      <div
        className={`${leftImage ? 'ml-8' : 'ml-0'} ${
          rightImage ? 'mr-8' : 'mr-0'
        } text-white text-md ${textSize}`}
      >
        {text}
      </div>
      {rightImage && <img src={rightImage}></img>}
    </AntButton>
  );
};

export default Button;
