import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query';

import {RootState} from '../store/store';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, {getState}) => {
    return headers;
  },
});

export default baseQuery;
