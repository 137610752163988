import {Spin, Table} from 'antd';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {getUser} from '../../features/auth/authSlice';
import {useGetFullDataMutation} from '../../store/prisonApi';
import {PrisonPaymentRequestJson, PrisonResponse} from '../../types';

const PrisonerProfile = () => {
  const user = useSelector(getUser);
  const [getData] = useGetFullDataMutation();
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState<PrisonResponse>();

  useEffect(() => {
    !_.isEmpty(user) && getPrisonerData();
  }, []);

  const getPrisonerData = async () => {
    await getData({
      register: user.register,
    })
      .unwrap()
      .then(async (response) => {
        const temp: PrisonResponse = {data: {first: [], second: [], third: []}};
        await response.data.first[0].map((el: any, inx: number) =>
          temp.data.first.push({id: inx, name: el}),
        );
        await response.data.second[0].map((el: any, inx: number) =>
          temp.data.second.push({id: inx, name: el}),
        );
        await response.data.third[0].map((el: any, inx: number) =>
          temp.data.third.push({id: inx, name: el}),
        );
        !_.isEmpty(temp) && setDataSource(temp);
      })
      .catch((e: ErrorCallback) => {
        console.log(e);
        alert('Error');
      });
    setLoading(false);
  };

  const jsonToBase64 = async (obj: PrisonPaymentRequestJson) => {
    return btoa(JSON.stringify(obj));
  };

  function base64ToJson(base64String: string) {
    const json = Buffer.from(base64String, 'base64').toString();
    return JSON.parse(json);
  }

  const columns = [
    {
      title: 'Мэдээлэл',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  return (
    <div className="mt-[48px]">
      <div className="px-[98px]">
        <Spin spinning={loading}>
          <h3 className="text-xl mb-[40px]">Мэдээлэл</h3>
          <div className="flex gap-6 mb-[40px]">
            <div className="rounded-md bg-gray overflow-hidden w-[300px] h-[400px]">
              {dataSource && (
                <img
                  className="object-cover h-full w-full"
                  src={`${
                    dataSource.data.first[dataSource.data.first.length - 1].name
                  }`}
                ></img>
              )}
            </div>
            <div className="flex-1 flex flex-col text-white justify-center ml-[100px]">
              <div className="flex gap-6 my-2">
                <h3 className="text-md font-medium">Овог, нэр :</h3>
                <h3 className="text-md font-medium">
                  {dataSource && dataSource.data.first[2].name}{' '}
                  {dataSource && dataSource.data.first[3].name}
                </h3>
              </div>
              <div className="flex gap-6 my-2 mb-[60px]">
                <h3 className="text-md font-medium">Регистр :</h3>
                <h3 className="text-md font-medium">{user.register}</h3>
              </div>
              {/* <div className="flex gap-6 my-2">
                <Button
                  text={'Мэдээлэл хэвлэх'}
                  onClick={async function (): Promise<void> {
                    setLoading(true);
                    await payLoad(
                      await jsonToBase64({
                        requestID: '7905',
                        portNo: '3',
                        timeout: '540000',
                        terminalID: '13168863',
                        amount: '1000',
                        currencyCode: '496',
                        operationCode: '1',
                        bandWidth: '115200',
                        cMode: '',
                        cMode2: '',
                        additionalData: '',
                        cardEntryMode: '',
                        fileData: '',
                      }),
                    )
                      .unwrap()
                      .then(async (response) => {
                        console.log(base64ToJson(response));
                      })
                      .catch((e: ErrorCallback) => {
                        console.log(e);
                        alert('Error');
                      });
                    setLoading(false);
                  }}
                  type={ButtonType.primary}
                  isActive={false}
                  isLoading={false}
                />
              </div> */}
            </div>
          </div>
          <h3 className="text-xl mb-[40px]">Хувийн дэлгэрэнгүй</h3>
          {dataSource && (
            <Table
              dataSource={dataSource.data.first}
              columns={columns}
              pagination={false}
              rowKey={'id'}
            />
          )}
          <h3 className="text-xl my-[40px]">Хувийн дэлгэрэнгүй</h3>
          {dataSource && (
            <Table
              dataSource={dataSource.data.second}
              columns={columns}
              pagination={false}
              rowKey={'id'}
            />
          )}
          <h3 className="text-xl my-[40px]">Хувийн дэлгэрэнгүй</h3>
          {dataSource && (
            <Table
              dataSource={dataSource.data.third}
              columns={columns}
              pagination={false}
              rowKey={'id'}
            />
          )}
          <div className="h-[100px]"></div>
        </Spin>
      </div>
    </div>
  );
};

export default PrisonerProfile;
