import {Spin} from 'antd';
import _ from 'lodash';
import React, { useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import image_tur from '../../assets/images/icon_tur.svg';
import Button, {ButtonType} from '../../component/Button';
import ServiceCard, {CardSize} from '../../component/ServiceCard';
import {getUser} from '../../features/auth/authSlice';
import {useGetSubServiceCategoriesByIdQuery} from '../../store/commonApi';
import { ServiceCategory} from '../../types';

const ServicesPage = () => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [selectedId, setSelectedId] = useState(9999);

  const params = useParams() as any;
  const location = useLocation();
  const {data, isLoading} = useGetSubServiceCategoriesByIdQuery(params.id);


  const getServicesWithPrison = (data: ServiceCategory[]) => {
    return [...data].map((d, i) => {
      return (
        <ServiceCard
          key={`key_${i}`}
          text={d.name}
          description={''}
          src={
            !_.isEmpty(d.icon) && !_.isEmpty(d.icon_active)
              ? selectedId == d.id
                ? d.icon_active
                : d.icon
              : image_tur
          }
          onClick={() => {
            setSelectedId(d.id);
          }}
          size={CardSize.small}
          isActive={selectedId == d.id ? true : false}
        />
      );
    });
  };

  return (
    <div className="mt-[48px] text-center">
      <p className="text-lg">Үйлчилгээний төрөл</p>
      <div className="mt-[56px] px-[296px] flex flex-wrap justify-center gap-1">
        {isLoading ? (
          <Spin />
        ) : data ? (
          getServicesWithPrison(data)
        ) :  (
          <p className="text-lg">Одоогоор бүртгэлтэй үйлчилгээ байхгүй байна</p>
        ) }
      </div>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />
        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            if (selectedId != 9999) {
                _.includes(location.pathname, 'prison')
                  ? navigate(
                      `/prison/subServices/${params.kiosk}/${selectedId}`,
                    )
                  : navigate(`/subServices/${params.kiosk}/${selectedId}`);
            }
          }}
          type={ButtonType.primary}
          isActive={false}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>
    </div>
  );
};

export default ServicesPage;
