import phone from '../assets/images/phone.svg';

const ContactInfo = () => {
  return (
    <div className="">
      <div className="flex items-center">
        <div className="bg-dark p-5 rounded-full max-h-[65px] mt-1">
          <img src={phone} alt="phone_icon"></img>
        </div>
        <div className="ml-4">
          <p className="text-green text-sm mb-2">Холбоо барих:</p>
          <h5 className="text-md my-0">7210-2283</h5>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
