import {Spin} from 'antd';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import Button, {ButtonType} from '../../component/Button';
import QpayInfo from '../../component/popup/QPay';
import {
  useCheckQPayByInvoiceIdMutation,
  useCreateQPayByIdMutation,
  useGetRequestDetailsByIdQuery,
} from '../../store/commonApi';
import {QPayCheckRequest, QPayCreationRequest} from '../../types';
import InvoiceTemplate from '../pos_templates/Invoice';

const ComplaintOverview = () => {
  const navigate = useNavigate();
  const [isPrintModalOpen, setPrintModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [qrImage, setQrImage] = useState('');
  const refer = useRef<HTMLDivElement>(null);

  const [isPaid, setIsPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [createQpayInvoice] = useCreateQPayByIdMutation();
  const [checkQpayInvoice] = useCheckQPayByInvoiceIdMutation();

  const params = useParams() as any;
  const {data, isLoading} = useGetRequestDetailsByIdQuery(params.id);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const createInvoice = async () => {
    setIsCreatingInvoice(true);
    const requestData: QPayCreationRequest = {
      id: params.id,
    };

    try {
      await createQpayInvoice(requestData)
        .unwrap()
        .then((response) => {
          setQrImage(response.data.qr_image);
          setInvoiceId(response.data.invoice_id);
          setIsModalOpen(true);
        })
        .catch((e: ErrorCallback) => {
          console.log(e);
          alert('Error');
        });
      setIsCreatingInvoice(false);
    } catch (e: any) {
      console.log(e.toString());
      setIsCreatingInvoice(false);
    }
  };

  const checkPayment = async () => {
    setErrorMessage('');
    const requestData: QPayCheckRequest = {
      invoice_id: invoiceId,
    };
    try {
      await checkQpayInvoice(requestData)
        .unwrap()
        .then((response) => {
          console.log(response);
          response.data.rows.length === 0 &&
            setErrorMessage('Гүйлгээ хараахан хийгдээгүй байна');

          console.log(!_.isEmpty(`${_.get(response.data, 'paid_amount')}`));

          if (!_.isEmpty(`${_.get(response.data, 'paid_amount')}`)) {
            setIsModalOpen(false);
            setIsPaid(true);
          }
        })
        .catch((e: ErrorCallback) => {
          console.log(e);
          alert('Error');
          setErrorMessage('Алдаа гарлаа');
        });
      setIsCreatingInvoice(false);
    } catch (e: any) {
      console.log(e.toString());
      setErrorMessage('Алдаа гарлаа');
    }
    return '';
  };

  const handlePrint = useReactToPrint({
    content: () => refer.current,
  });

  return (
    <div className="mt-[48px]">
      <Spin spinning={isLoading}>
        <div className="px-[106px] flex">
          <div className="flex-1 flex flex-col items-start justify-between pb-[60px]">
            <div>
              <h3 className="text-xl mb-[40px] mt-[32px]">
                Хүсэлтийн дугаар: {data?.request_uid}
                <br /> {data?.service.name}
              </h3>
              <p className="font-light text-22 leading-6 mt-2">
                <span className="text-green">Тайлбар:</span>{' '}
                {data?.service.warning_info}
              </p>
              <p className="font-light text-22 leading-6 mt-2">
                <span className="text-green">Хүлээн авах ажилтан:</span>{' '}
                {data?.specialist_info}
              </p>
              <hr className="mr-auto mt-5" />
              <p className="font-light text-22 leading-6 mt-5">
                <span className="text-green">Анхааруулга мессэж:</span>{' '}
                {data?.service.warning_info}
              </p>
              <div className="hidden">
                <div className="mt-[40px] p-0 text-black" ref={refer}>
                  <InvoiceTemplate
                    aimag={data?.aimag || ''}
                    sum={data?.sum || ''}
                    id={params.id}
                    uniqueNum={data?.request_uid || ''}
                    firstname={data?.request_creator_name || ''}
                    regnum={data?.request_creator_register || ''}
                    service_name={data?.service.name || ''}
                    price={
                      data ? data.service.price - data.service.price * 0.01 : 0
                    }
                    bank_commision={data ? data.service.price * 0.01 : 0}
                    total={data?.service.price || 0}
                  ></InvoiceTemplate>
                </div>
              </div>
            </div>
          </div>
          <div className="rounded-md w-[806px] bg-gray h-[667px] py-[18px] px-[40px] overflow-y-scroll flex flex-col items-center text-center">
            <div className="flex-1 bg-gray rounded-lg py-4 px-[24px] mt-6 max-w-[624px]">
              <p className="font-light text-22 leading-6 mt-2">
                Та төлбөр төлсний дараа{' '}
                <span className="text-green">
                  {' '}
                  буцаан олголт авах боломжгүй{' '}
                </span>{' '}
                тул сайтар нягталж харна уу? <br />
                <br />
                Хэвлэгдсэн баримтыг хадгалахаа мартаж болохгүй шүү?
              </p>
            </div>
            <div className="pb-[40px]">
              {!isPaid && (
                <Button
                  text={'Төлбөр төлөх'}
                  onClick={createInvoice}
                  type={ButtonType.primary}
                  isActive={isLoading}
                  isLoading={isCreatingInvoice}
                  rightImage={icon_button_right}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />
        <Button
          text={
            isLoading
              ? ''
              : isPrintModalOpen
              ? 'Өргөдөл илгээгдлээ'
              : 'Өргөдөл илгээх'
          }
          onClick={function (): void {
            setPrintModal(true);
            handlePrint();
          }}
          type={ButtonType.primary}
          isLoading={false}
          isActive={isPaid ? isPrintModalOpen : !isPaid}
          rightImage={icon_button_right}
        />
      </div>
      <QpayInfo
        isModalOpen={isModalOpen}
        qrImage={qrImage}
        errorMessage={errorMessage}
        checkPayment={checkPayment}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
      ></QpayInfo>
    </div>
  );
};

export default ComplaintOverview;
