export const theme = {
  token: {
    colorPrimary: '#D0BE9A',
    colorPrimaryHover: '#444444',
    colorWarning: '#444444',
    colorBorder: '#444444',
    colorError: '#444444',
    colorErrorHover: '#444444',
    lineType: 'solid',
    controlHeight: 50,
    controlHeightSM: 44,
    controlHeightLG: 60,
    fontSize: 20,
    borderRadius: 5,
    borderRadiusSM: 7,
    borderRadiusLG: 10,
  },
};
