import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {Camera} from '../../component/faceComponents/Camera';
import {FormTabs} from '../../component/faceComponents/FormTabs';

const LoginPage = () => {
  const params = useParams() as any;

  useEffect(() => {
    localStorage.setItem('kiosk', params.id);
  }, []);

  return (
    <div className="mt-[48px] text-center">
      <div className="mt-[56px] flex justify-center">
        <div className="grid grid-cols-2 w-[1200px]">
          <div className="col-span-1 relative">
            <Camera />
          </div>
          <div className="col-span-1 z-99">
            <FormTabs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
