import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';

import ContactInfo from './ContactInfo';
import exit from '../assets/images/exit.png';
import logo from '../assets/images/logo.svg';
import {getUser, logoutUser} from '../features/auth/authSlice';

const Header = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(getUser);
  return (
    <div className="flex justify-between items-center h-[150px] bg-gray6 px-[100px]">
      <img src={logo} alt="minu_logo"></img>
      <div className="flex gap-5 items-center justify-center">
        <ContactInfo></ContactInfo>
        <a
          onClick={() => {
            if (!_.includes(location.pathname, 'login')) {
              if (_.includes(location.pathname, 'prison')) {
                dispatch(logoutUser());
                navigate(`/prison/${localStorage.getItem('kiosk')}`);
              } else navigate(`/${localStorage.getItem('kiosk')}`);
            }
          }}
          className="flex items-center ml-[50px]"
        >
          <img width={'40px'} src={exit} alt="exit" />
          <p className="text-white ml-[10px]">Дахин эхлэх</p>
        </a>
      </div>
    </div>
  );
};

export default Header;
