import {Routes, Route} from 'react-router-dom';

import AuthMiddleware from './middleware';
import {authProtectedRoutes, publicRoutes} from './routes';
import PublicLayout from '../layouts/Public.layout';

import '../assets/styles/index.css';

const Router = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        {publicRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware isAuthProtected={false}>
                <route.component />
              </AuthMiddleware>
            }
          />
        ))}
      </Route>
      <Route element={<PublicLayout />}>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware isAuthProtected={true}>
                <route.component />
              </AuthMiddleware>
            }
          />
        ))}
      </Route>
    </Routes>
  );
};

export default Router;
