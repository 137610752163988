import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query';

const prisonPaymentQuery = fetchBaseQuery({
  baseUrl: 'http://localhost:8500',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', `application/json`);
    return headers;
  },
});

export default prisonPaymentQuery;
