import {Spin} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';
import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Keyboard from 'react-simple-keyboard';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import Button, {ButtonType} from '../../component/Button';
import AuthStatus from '../../component/popup/AuthStatus';
import RegisterInputs from '../../component/popup/RegisterInputs';
import {formatCurrency} from '../../helpers/utils';
import {layout} from '../../helpers/utils';
import {
  useCreateRequestByIdMutation,
  useGetServiceDetailsByIdQuery,
} from '../../store/commonApi';
import {RequestCreationRequest} from '../../types';

const ComplaintDetail = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [input, setInput] = useState('');
  const keyboard = useRef(null);

  const params = useParams() as any;
  const {data, isLoading} = useGetServiceDetailsByIdQuery(params.id);
  const [createRequest] = useCreateRequestByIdMutation();
  const [regnum, setRegNum] = useState('');
  const [phone, setPhoneNum] = useState('');
  const [endpoints, setEndPoints] = useState<string[]>();
  const [serviceUrls, setServiceUrls] = useState<string[]>();
  const [templates, setTemplates] = useState<string[]>();

  const [isCreatingRequest, setCreateRequest] = useState(false);

  useEffect(() => {
    !_.isEmpty(regnum) && setIsAuthModalOpen(true);
  }, [regnum]);

  useEffect(() => {
    if (!_.isEmpty(data?.xyp)) {
      const temp: string[] = [];
      const temp_urls: string[] = [];
      const temp_names: string[] = [];
      data?.xyp.map((val) => {
        temp.push(val.xyp.endpoint);
        temp_urls.push(val.xyp.service_url);
        temp_names.push(val.xyp.template_name);
      });
      setEndPoints(temp);
      setServiceUrls(temp_urls);
      setTemplates(temp_names);
    }
  }, [data]);

  const createReq = async (file_tokens: string[]) => {
    setCreateRequest(true);
    const requestData: RequestCreationRequest = {
      kiosk_service: params.kiosk,
      request_creator_register: regnum,
      file_tokens: file_tokens,
      request_creator_phone: phone,
      complaint_text: input,
    };
    console.log(requestData);
    await createRequest(requestData)
      .unwrap()
      .then((response) => {
        setCreateRequest(false);
        navigate(`/complaint/${response.id}`);
      })
      .catch((e: ErrorCallback) => {
        console.log(e);
        alert('Error');
        setCreateRequest(false);
      });
  };

  const onChangeInput = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const input = event.target.value;
    setInput(input);
  };

  return (
    <div className="mt-[48px]">
      <Spin spinning={isLoading || isCreatingRequest}>
        <div className="px-[98px] flex flex-col justify-between ml-[63px]">
          <div className="flex-1">
            <h3 className="text-center text-xl mb-[40px] mt-[16px]">
              Та өргөдлийнхөө мэдээллийг оруулна уу.
            </h3>
            <TextArea
              value={input}
              onChange={(e) => onChangeInput(e)}
              placeholder="Энд өргөдлийн утгаа бичнэ үү"
              className="h-[70px] bg-gray text-white"
            ></TextArea>
          </div>
        </div>
      </Spin>
      <div className="absolute left-0 bottom-[170px] h-[425px] w-screen bg-background">
        <Keyboard
          keyboardRef={(ref) => keyboard}
          onChange={setInput}
          layout={layout}
        ></Keyboard>
      </div>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />

        <div className="flex gap-4 justify-between">
          {data && (
            <>
              <h3 className="text-xl mb-[40px] mt-[32px] ml-[60px]">
                Нийт үнэ:
              </h3>
              <h3 className="text-green text-xl mb-[40px] mt-[32px]">
                {isLoading
                  ? '-'
                  : data
                  ? data.total == 0
                    ? 'Үнэгүй'
                    : `${formatCurrency(data.total)}`
                  : '0₮'}
              </h3>
            </>
          )}
        </div>
        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            if (_.isEmpty(regnum)) setIsModalOpen(true);
          }}
          type={ButtonType.primary}
          isActive={isCreatingRequest ? isCreatingRequest : isLoading}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>

      <RegisterInputs
        isModalOpen={isModalOpen}
        handleOk={() => {
          setIsModalOpen(false);
        }}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
        setPhoneNum={setPhoneNum}
        setRegNum={setRegNum}
      ></RegisterInputs>

      <AuthStatus
        isModalOpen={isAuthModalOpen}
        handleOk={(data: string[]) => {
          setIsAuthModalOpen(false);
          createReq(data);
        }}
        handleCancel={() => {
          setRegNum('');
          setIsAuthModalOpen(false);
        }}
        regnum={regnum}
        endpoints={endpoints}
        urls={serviceUrls}
        names={templates}
      ></AuthStatus>
    </div>
  );
};

export default ComplaintDetail;
