import React from 'react';
import {useNavigate} from 'react-router-dom';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import icon_pdf from '../../assets/images/icon_pdf.svg';
import Button, {ButtonType} from '../../component/Button';
import {Routes} from '../../routes/routes';

interface ApplicationMockData {
  title: string;
  shortDesc: string;
  url: string;
  type: string;
  price: number;
}

const ApplicationDetails = () => {
  const navigate = useNavigate();

  const data: ApplicationMockData[] = [
    {
      title: 'Иргэний үнэмлэхний хуулбар',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'default',
      price: 20000,
    },
    {
      title: 'Оршин суугаа газрын тодорхойлолт',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'default',
      price: 0,
    },
    {
      title: 'Төрсний гэрчилгээ',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'default',
      price: 0,
    },
    {
      title: 'Ажил эрхлэлт',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'default',
      price: 20000,
    },
    {
      title: 'Гэмт хэрэгт холбогдсон эсэх баримт',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'default',
      price: 10000,
    },
    {
      title: 'Газрын кадастрын зураг',
      shortDesc: 'ХУР системээс татагдах боломжтой.',
      url: '',
      type: 'yourself',
      price: 0,
    },
  ];

  return (
    <div className="mt-[48px]">
      <div className="px-[98px] flex">
        <div className="rounded-md w-full bg-gray h-[657px] py-[37px] px-[57px] overflow-y-scroll">
          <h3 className="text-xl mb-[40px]">Бүрдүүлэх тодорхойлолт</h3>
          {data.map((d, i) => {
            return (
              <div
                className="flex justify-between border border-white py-2"
                key={`key_${i}`}
              >
                <div>
                  <h5 className="text-22">{d.title}</h5>
                  <p className="font-light text-xs mt-2">{d.shortDesc}</p>
                </div>
                <div className="flex items-center gap-4">
                  <div className="flex items-center mr-4">
                    <img src={icon_pdf}></img>
                    <p className="font-light text-22">Todorkhoilolt_1.pdf</p>
                  </div>
                  <Button
                    text={'Шалгах'}
                    onClick={function (): void {
                      navigate(Routes.home);
                    }}
                    type={ButtonType.primary}
                    isActive={false}
                    isLoading={false}
                    textSize="text-20"
                    className="px-[24px] py-[8px]"
                  />
                  <Button
                    text={'Хэвлэх'}
                    onClick={function (): void {
                      navigate(-1);
                    }}
                    type={ButtonType.bordered}
                    isActive={false}
                    isLoading={false}
                    textSize="text-20"
                    className="px-[24px] py-[8px]"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />

        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            navigate(Routes.reportOverview);
          }}
          type={ButtonType.primary}
          isActive={false}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>
    </div>
  );
};

export default ApplicationDetails;
