import React from "react"
import { useDispatch, useSelector } from "react-redux"

import Button, { ButtonType } from '../../component/Button';
import { getCameraStatus, getScreenshot, setCameraStatus, setScreenshot, setIsFlashing, getActiveSource } from "../../features/auth/authSlice"
import { setFacenetMessage, setOutline } from "../../features/auth/facenetSlice"

export const PictureControls = () => {
    const dispatch = useDispatch()
    const cameraStatus = useSelector(getCameraStatus)
    const screenshot = useSelector(getScreenshot)
    const activeSource = useSelector(getActiveSource)

    const handleCameraClosing = () => {
        dispatch(setCameraStatus('closed'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }

    const handleCameraOpening = () => {
        dispatch(setCameraStatus('opened'))
        dispatch(setScreenshot(null))
        dispatch(setOutline('#ddd'))
        dispatch(setFacenetMessage('Place the face in the oval.'))
    }

    return (
        <div className="flex gap-4">
            {
                activeSource === 'webcam' && <Button
                    text={screenshot != null ? 'Дахин эхлэх' : (cameraStatus === 'opened' ? 'Унтраах' : 'Идэвхижүүлэх')}
                    onClick={() => cameraStatus === 'closed' ? handleCameraOpening() : handleCameraClosing()}
                    type={ButtonType.primary}
                    className="mt-[100px] flex-1"
                    isActive={false}
                    isLoading={false} />
            }
        </div>
    )
}