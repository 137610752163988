import {useDispatch, useSelector} from 'react-redux';

import {Alert} from './Alert';
import {Login} from './Login';
import {Register} from './Register';
import {getActiveTab, setActiveTab} from '../../features/auth/authSlice';

export const FormTabs = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  const enableInput = (elementId) => {
    const el = document.getElementById(elementId);
    el.disabled = false;
    el.focus();
  };

  return (
    <>
      {/* <ul className="nav nav-tabs">
                <li
                    className={'nav-item ' + (activeTab === 'login' ? 'active' : '')}
                    onClick={() => dispatch(setActiveTab('login'))}
                >Login</li>
            </ul> */}
      <div className="">
        <Alert />
        <Login enableInput={enableInput} />
        {/* <Register enableInput={enableInput} /> */}
      </div>
    </>
  );
};
