import {Spin} from 'antd';
import axios from 'axios';
import _ from 'lodash';
import React, {RefObject, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import Button, {ButtonType} from '../../component/Button';
import QpayInfo from '../../component/popup/QPay';
import {getUser} from '../../features/auth/authSlice';
import {
  useCheckQPayByInvoiceIdMutation,
  useCreateQPayByIdMutation,
  useGetRequestDetailsByIdQuery,
} from '../../store/commonApi';
import {
  PrisonPaymentRequestJson,
  PrisonPaymentResponse,
  QPayCheckRequest,
  QPayCreationRequest,
} from '../../types';
import InvoiceTemplate from '../pos_templates/Invoice';

const ReportOverview = () => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [isPrintModalOpen, setPrintModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState('');
  const [qrImage, setQrImage] = useState('');
  const referReceipt = useRef<HTMLDivElement>(null);
  const refer = useRef<HTMLIFrameElement>(null);
  const [refers, setRefers] = useState<RefObject<HTMLIFrameElement>[]>();

  const [isPaid, setIsPaid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [createQpayInvoice] = useCreateQPayByIdMutation();
  const [checkQpayInvoice] = useCheckQPayByInvoiceIdMutation();

  const params = useParams() as any;
  const {data, isLoading} = useGetRequestDetailsByIdQuery(params.id);

  useEffect(() => {
    setRefers(updateRefs());
  }, [data]);

  const updateRefs = () => {
    const iframeRefs: RefObject<HTMLIFrameElement>[] = [];
    for (const d in data?.data) {
      iframeRefs.push(React.createRef());
    }
    return iframeRefs;
  };

  const jsonToBase64 = async (obj: PrisonPaymentRequestJson) => {
    return btoa(JSON.stringify(obj));
  };

  const createInvoice = async () => {
    if (_.isEmpty(user)) {
      setIsCreatingInvoice(true);
      const requestData: QPayCreationRequest = {
        id: params.id,
      };
      try {
        await createQpayInvoice(requestData)
          .unwrap()
          .then((response) => {
            setQrImage(response.data.qr_image);
            setInvoiceId(response.data.invoice_id);
            setIsModalOpen(true);
          })
          .catch((e: ErrorCallback) => {
            console.log(e);
            alert('Error');
          });
        setIsCreatingInvoice(false);
      } catch (e: any) {
        console.log(e.toString());
        setIsCreatingInvoice(false);
      }
    } else {
      setIsCreatingInvoice(true);
      const reqData = await jsonToBase64({
        requestID: `${params.id}`,
        portNo: '9',
        timeout: '540000',
        terminalID: '13168863',
        amount: '1000',
        currencyCode: '496',
        operationCode: '1',
        bandWidth: '115200',
        cMode: '',
        cMode2: '',
        additionalData: '',
        cardEntryMode: '',
        fileData: '',
      });
      const {data, status} = await axios.get<PrisonPaymentResponse>(
        `http://localhost:8500/requestToPos/message?data=${reqData}`,
        {
          headers: {},
        },
      );
      console.log(data.PosResult);
      const resultJson = JSON.parse(data.PosResult);

      if (resultJson.responseCode != 0) {
        alert(resultJson.responseDesc);
      } else {
        setIsPaid(true);
      }
      setIsCreatingInvoice(false);
    }
  };

  const checkPayment = async () => {
    setErrorMessage('');
    const requestData: QPayCheckRequest = {
      invoice_id: invoiceId,
    };
    try {
      await checkQpayInvoice(requestData)
        .unwrap()
        .then((response) => {
          console.log(response);
          response.data.rows.length === 0 &&
            setErrorMessage('Гүйлгээ хараахан хийгдээгүй байна');

          console.log(!_.isEmpty(`${_.get(response.data, 'paid_amount')}`));

          if (!_.isEmpty(`${_.get(response.data, 'paid_amount')}`)) {
            setIsModalOpen(false);
            setIsPaid(true);
          }
        })
        .catch((e: ErrorCallback) => {
          console.log(e);
          alert('Error');
          setErrorMessage('Алдаа гарлаа');
        });
      setIsCreatingInvoice(false);
    } catch (e: any) {
      console.log(e.toString());
      setErrorMessage('Алдаа гарлаа');
    }
    return '';
  };

  const printAll = async () => {
    const name = data?.service.request_type_name;
    if (name != 'APPLICATION') handlePrint();
    const duration = name != 'APPLICATION' ? 7000 : 0;

    setTimeout(() => {
      handleReceipt();
    }, duration);
  };

  const handleReceipt = useReactToPrint({
    content: () => referReceipt.current,
  });

  const handlePrint = useReactToPrint({
    content: () => refer.current,
    onAfterPrint: () => {
      console.log('printed');
    },
  });

  return (
    <div className="mt-[48px]">
      <Spin spinning={isLoading}>
        <div className="px-[98px] flex">
          {data && data.service.request_type_name}
          <div className="rounded-md w-[806px] bg-gray h-[667px] py-[18px] px-[40px] overflow-y-scroll flex flex-col items-center justify-start text-center">
            {refers &&
              data?.data.map((file, ind) => (
                <iframe
                  key={file.file_token}
                  id={`frame-${ind}`}
                  ref={refer}
                  src={`${
                    process.env.REACT_APP_NAKED_URL
                  }kiosk/${data.service.request_type_name.toLowerCase()}/${
                    file.file_token
                  }`}
                  className={`${
                    data.service.request_type_name == 'APPLICATION'
                      ? 'w-[672px] min-h-[790px]'
                      : 'w-[272px] min-h-[790px]'
                  }`}
                  style={{marginLeft: '-13px', marginTop: '-15px'}}
                ></iframe>
              ))}
          </div>
          <div className="flex-1 flex flex-col justify-between ml-[63px]">
            <div>
              <h3 className="text-xl mb-[40px] mt-[32px]">
                Хүсэлтийн дугаар: {data?.request_uid}
                <br /> {data?.service.name}
              </h3>
              <p className="font-light text-sm leading-6 mt-2">
                {data?.service.warning_info}
              </p>
              <div className="bg-gray rounded-lg py-4 px-[24px] mt-6 max-w-[624px]">
                <p className="font-light text-sm leading-6 mt-2">
                  Та төлбөр төлсний дараа{' '}
                  <span className="text-green">
                    {' '}
                    буцаан олголт авах боломжгүй{' '}
                  </span>{' '}
                  тул сайтар нягталж харна уу? <br />
                  <br />
                  Хэвлэгдсэн баримтыг хадгалахаа мартаж болохгүй шүү?
                </p>
              </div>
            </div>
            <div>
              {!isPaid && (
                <Button
                  text={'Төлбөр төлөх'}
                  onClick={createInvoice}
                  type={ButtonType.primary}
                  isActive={isLoading}
                  isLoading={isCreatingInvoice}
                  rightImage={icon_button_right}
                />
              )}
            </div>
          </div>
        </div>
        <div className="hidden">
          <div className="mt-[40px] p-0 text-black" ref={referReceipt}>
            <InvoiceTemplate
              aimag={data?.aimag || ''}
              sum={data?.sum || ''}
              id={params.id}
              uniqueNum={data?.request_uid || ''}
              firstname={data?.request_creator_name || ''}
              regnum={data?.request_creator_register || ''}
              service_name={data?.service.name || ''}
              price={data ? data.service.price - data.service.price * 0.01 : 0}
              bank_commision={data ? data.service.price * 0.01 : 0}
              total={data?.service.price || 0}
            ></InvoiceTemplate>
          </div>
        </div>
      </Spin>

      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />
        <Button
          text={
            isLoading
              ? ''
              : data?.service.request_type_name == 'STATEMENT'
              ? 'Лавлагаа хэвлэх'
              : 'Хүсэлт илгээх'
          }
          onClick={function (): void {
            setPrintModal(true);
            printAll();
          }}
          type={ButtonType.primary}
          isLoading={false}
          isActive={!isPaid}
          rightImage={icon_button_right}
        />
      </div>

      <QpayInfo
        isModalOpen={isModalOpen}
        qrImage={qrImage}
        errorMessage={errorMessage}
        checkPayment={checkPayment}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
      ></QpayInfo>
    </div>
  );
};

export default ReportOverview;
function base64ToJson(response: string): any {
  throw new Error('Function not implemented.');
}
function jsonToBase64(arg0: {
  requestID: string;
  portNo: string;
  timeout: string;
  terminalID: string;
  amount: string;
  currencyCode: string;
  operationCode: string;
  bandWidth: string;
  cMode: string;
  cMode2: string;
  additionalData: string;
  cardEntryMode: string;
  fileData: string;
}): string | PromiseLike<string> {
  throw new Error('Function not implemented.');
}
