import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'


const initialState = {
    image: '',
    imageError: null,
    detections: {
        ssd_mobilenetv1:{
            status: null,
            faces: [],
            time: 0,
            error: null
        },
        tiny_face_detector:{
            status: null,
            faces: [],
            time: 0,
            error: null
        },
        mtcnn:{
            status: null,
            faces: [],
            time: 0,
            error: null
        },
        tiny_yolov2:{
            status: null,
            faces: [],
            time: 0,
            error: null
        },
    }
}

export const detectFacesByNet = createAsyncThunk(
    'detect/detectFacesByNet',
)

export const detectSlice = createSlice({
    name: 'detect',
    initialState,
    reducers: {
        setDetectImage: (state, action) => {
            state.image = action.payload
        },
        setDetectImageError: (state, action) => {
            state.imageError = action.payload
        },
        resetDetections: (state) => {
            const emptyObject = {status: null,faces: [],time: 0,error: null}
            state.detections = {
                ssd_mobilenetv1: emptyObject,
                tiny_face_detector: emptyObject,
                mtcnn: emptyObject,
                tiny_yolov2: emptyObject,
            }
        },
        setDetectTime: (state, action) => {
            state.detections[action.payload.net].time = action.payload.time
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(detectFacesByNet.pending, (state, action) => {
            state.detections[action.meta.arg].status = 'pending'
        })
        .addCase(detectFacesByNet.fulfilled, (state, action) => {
            state.detections[action.meta.arg].status = 'fulfilled'
            if (action.payload.length === 0) {
                state.detections[action.meta.arg].error = 'A face could not be detected.'
            }
            state.detections[action.meta.arg].faces = action.payload
        })
        .addCase(detectFacesByNet.rejected, (state, action) => {
            state.detections[action.meta.arg].status = 'rejected'
            state.detections[action.meta.arg].error = 'A general error occurred with face detection. Please, try again.'
        })
    }
})

export const getDetectImage = state => state.detect.image
export const getDetectImageError = state => state.detect.imageError
export const getDetections = state => state.detect.detections

export const { setDetectImage, setDetectImageError, resetDetections, setDetectTime } = detectSlice.actions
export default detectSlice.reducer