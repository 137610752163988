import {compile} from 'path-to-regexp';

import ApplicationList from '../pages/applications/ApplicationList';
import ApplicationPrisonList from '../pages/applications/ApplicationPrisonList';
import ApplicationDetails from '../pages/applications/ApplicationsDetails';
import ComplaintDetail from '../pages/applications/ComplaintDetail';
import PrisonerProfile from '../pages/applications/PrisonerProfile';
import HomePage from '../pages/home/HomePage';
import PrisonHomePage from '../pages/home/PrisonHome';
import LoginPage from '../pages/login/Login';
import ComplaintOverview from '../pages/report/ComplaintsOverview';
import ReportOverview from '../pages/report/ReportOverview';
import ServicesPage from '../pages/services/ServicesPage';
import SubServices from '../pages/subServices/SubServices';

const Routes = {
  home: '/:id',
  login: '/detect',
  services: '/services/:kiosk/:id',
  subServices: '/subServices/:kiosk/:id',
  applications: '/applications/:kiosk/:id',
  complaintDetail: '/complaintDetail/:kiosk/:id',
  myApplications: '/myApplications',
  reportOverview: '/report/:id',
  complaintOverview: '/complaint/:id',
  invoice: '/invoice/:id',

  phome: '/prison/:id',
  pservices: '/prison/services/:kiosk/:id',
  psubServices: '/prison/subServices/:kiosk/:id',
  papplications: '/prison/applications/:kiosk/:id',
  pcomplaintDetail: '/prison/complaintDetail/:kiosk/:id',
  pmyApplications: '/prison/myApplications',
  preportOverview: '/prison/report/:id',
  pcomplaintOverview: '/prison/complaint/:id',
  pinvoice: '/prison/invoice/:id',

  profile: '/prison/profile',
  compilePath(path: string, param: object) {
    const toPath = compile(path, {encode: encodeURIComponent});
    return toPath(param);
  },
};

const publicRoutes = [
  {path: Routes.home, component: HomePage},
  {path: Routes.login, component: LoginPage},
  {path: Routes.services, component: ServicesPage},
  {path: Routes.subServices, component: SubServices},
  {path: Routes.applications, component: ApplicationList},
  {path: Routes.myApplications, component: ApplicationDetails},
  {path: Routes.reportOverview, component: ReportOverview},
  {path: Routes.complaintOverview, component: ComplaintOverview},
  {path: Routes.complaintDetail, component: ComplaintDetail},
  // {path: Routes.phome, component: PrisonHomePage},
  // {path: Routes.pservices, component: ServicesPage},
];

const authProtectedRoutes = [
  {path: Routes.phome, component: PrisonHomePage},
  {path: Routes.pservices, component: ServicesPage},
  {path: Routes.psubServices, component: SubServices},
  {path: Routes.papplications, component: ApplicationPrisonList},
  {path: Routes.pmyApplications, component: ApplicationDetails},
  {path: Routes.preportOverview, component: ReportOverview},
  {path: Routes.pcomplaintOverview, component: ComplaintOverview},
  {path: Routes.pcomplaintDetail, component: ComplaintDetail},
  {path: Routes.profile, component: PrisonerProfile}
];

export {Routes, publicRoutes, authProtectedRoutes};
