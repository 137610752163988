import {configureStore} from '@reduxjs/toolkit';

import {commonApi} from './commonApi';
import { prisonApi } from './prisonApi';
import { prisonPaymentApi } from './prisonPosApi';
import {xypApi} from './xypApi';
import authReducer from '../features/auth/authSlice';
import facenetReducer from '../features/auth/facenetSlice';
import detectReducer from '../features/dashboard/detectSlice';
import matchReducer from '../features/dashboard/matchSlice';
import similarityReducer from '../features/dashboard/similaritySlice';
import userReducer from '../features/dashboard/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [xypApi.reducerPath]: xypApi.reducer,
    [prisonApi.reducerPath]: prisonApi.reducer,
    [prisonPaymentApi.reducerPath]: prisonPaymentApi.reducer,
    facenet: facenetReducer,
    user: userReducer,
    match: matchReducer,
    detect: detectReducer,
    similarity: similarityReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false})
      .concat(commonApi.middleware)
      .concat(xypApi.middleware)
      .concat(prisonPaymentApi.middleware)
      .concat(prisonApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
