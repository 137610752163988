import * as _ from 'lodash';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';

import {getUser} from '../features/auth/authSlice';

type AuthMiddlewareProps = {
  isAuthProtected: boolean;
  children: JSX.Element;
};

const AuthMiddleware = ({isAuthProtected, children}: AuthMiddlewareProps) => {
  const user = useSelector(getUser);

  if (isAuthProtected && _.isEmpty(user)) {
    return <Navigate to="/detect" />;
  } else {
    return children;
  }
};

export default AuthMiddleware;
