import {Modal, Spin, Input} from 'antd';
import _ from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';
import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import {useVeryXypOTPMutation, useXypOTPMutation} from '../../store/xypApi';
import Button, {ButtonType} from '../Button';

const AuthStatusInputs = ({
  isModalOpen,
  handleOk,
  handleCancel,
  regnum,
  endpoints,
  urls,
  names,
}) => {
  const [authResponse] = useXypOTPMutation();
  const [verifyResponse] = useVeryXypOTPMutation();

  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState('default');
  const keyboard = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isInputActive, setInputActive] = useState(true);

  const layout = {
    default: [
      '~ 1 2 3 4 5 6 7 8 9 0 Е Щ {bksp}',
      '{tab} Ф Ц У Ж Э Н Г Ш Ү З К Ъ |',
      '{lock} Й Ы Б Ө А Х Р О Л Д П {enter}',
      '{shift} Я Ч Ё С М И Т Ь В Ю {shift}',
      '.com @ {space}',
    ],
    shift: [
      '~ 1 2 3 4 5 6 7 8 9 0 Е Щ {bksp}',
      '{tab} Ф Ц У Ж Э Н Г Ш Ү З К Ъ |',
      '{lock} Й Ы Б Ө А Х Р О Л Д П {enter}',
      '{shift} Я Ч Ё С М И Т Ь В Ю {shift}',
      '.com @ {space}',
    ],
  };

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    if (inputVal.length == 6) setInputActive(false);
    else setInputActive(true);
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const onChangeAll = (inputs) => {
    setInputs(inputs);
  };

  const onChange = (inputs) => {
    if (inputs.length == 6) setInputActive(false);
    else setInputActive(true);
    setInputs({...inputs});
  };

  const getInputValue = (inputName) => {
    return inputs[inputName] || '';
  };

  useEffect(() => {
    !_.isEmpty(regnum) && isLoading && console.log('called');
    !_.isEmpty(regnum) && isLoading && createReq();
  }, [regnum]);

  const createReq = async () => {
    await authResponse({
      regnum: regnum,
      services: endpoints,
    })
      .unwrap()
      .then((result) => {
        result.resultCode != 0
          ? alert(result.resultMessage)
          : setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const createVerifyReq = async () => {
    setIsVerifyLoading(true);
    const requestData = {
      regnum: regnum,
      services: endpoints,
      services_url: urls,
      otp: getInputValue('authcode'),
      templates_name: names,
      additional_parameters: {
        startYear: 2019,
        endYear: 2023,
      },
    };
    console.log(requestData);
    await verifyResponse(requestData)
      .unwrap()
      .then((resp) => {
        setIsVerifyLoading(false);
        handleOk(resp);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal
      className="bg-transparent min-w-full min-h-screen p-0 top-0"
      open={isModalOpen}
      footer={[
        <Button
          key={1}
          text={'Цуцлах'}
          type={ButtonType.bordered}
          onClick={handleCancel}
          isLoading={false}
          isActive={false}
          leftImage={icon_button_left}
        />,
        <Button
          key={2}
          text={'Бөглөх'}
          type={ButtonType.primary}
          onClick={() => {
            createVerifyReq();
          }}
          isLoading={false}
          isActive={isInputActive}
          rightImage={icon_button_right}
        />,
      ]}
    >
      <div className="text-white bg-background max-w-[1200px] min-h-[400px]">
        {isLoading && (
          <h5 className="text-md font-bold text-center">
            <span className="text-green">{regnum}</span> регистрийн дугаартай
            хэрэглэгчийн мэдээллийг татаж байна
          </h5>
        )}
        <div className="flex flex-col gap-6 justify-center">
          {isLoading ? (
            <Spin className="mt-[40px]"></Spin>
          ) : (
            <>
              <h5 className="text-md font-bold text-center">
                <span className="text-green">6 </span>
                оронтой тоог таны бүртгэлтэй дугаарлуу явуулсан байна та
                баталгаажуулна уу
              </h5>
              {isVerifyLoading ? (
                <Spin className="mt-[40px]"></Spin>
              ) : (
                <div className="mx-auto max-w-[400px] mt-[40px]">
                  <p className="text-sm text-green mb-3">Баталгаажуулах код</p>
                  <Input
                    value={getInputValue('authcode')}
                    onFocus={() => setInputName('authcode')}
                    onChange={onChangeInput}
                    type="number"
                    placeholder="XXXXXX"
                    className="h-[70px] w-[400px] bg-gray text-white"
                  ></Input>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="absolute left-0 bottom-0 h-[425px] w-screen bg-background">
        <Keyboard
          keyboardRef={(r) => (keyboard.current = r)}
          onChange={(e) => onChange(e)}
          onChangeAll={onChangeAll}
          inputName={inputName}
          layout={layout}
        ></Keyboard>
      </div>
    </Modal>
  );
};

export default AuthStatusInputs;
