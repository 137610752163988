import {Modal} from 'antd';
import * as _ from 'lodash';
import React, {useState} from 'react';

import exit from '../../assets/images/close.png';
import Button, {ButtonType} from '../Button';

interface ModalData {
  isModalOpen: boolean;
  qrImage: string;
  errorMessage: string;
  handleCancel: () => void;
  checkPayment: () => Promise<string>;
}

const QpayInfo: React.FC<ModalData> = ({
  isModalOpen,
  checkPayment,
  errorMessage,
  qrImage,
  handleCancel,
}) => {
  const [isChecking, setIsChecking] = useState(false);

  const check = async () => {
    setIsChecking(true);
    await checkPayment();
    setIsChecking(false);
  };

  return (
    <Modal
      className="bg-transparent min-w-full min-h-screen p-0 top-[110px]"
      open={isModalOpen}
      footer={[]}
    >
      <div className="text-white bg-background max-w-[1200px] min-h-[600px] text-center my-auto">
        <div className="flex justify-end">
          <div onClick={handleCancel} className="cursor-pointer">
            <img src={exit} />
          </div>
        </div>
        <div className="max-w-[700px] mx-auto">
          <div className="text-xl font-bold">Төлбөр төлөх</div>
          <div className="bg-white p-3 w-[240px] h-[240px] mx-auto mt-[48px]">
            <img
              alt="qr"
              width="100%"
              src={`data:image/png;base64,${qrImage}`}
            ></img>
          </div>
          <div className="pt-[30px] text-center mt-[30px]">
            <p className="text-sm mb-3">
              Та дурын Банкны аппликейшнаар төлбөрөө төлөөрэй.
            </p>
            <Button
              key={1}
              text={'Төлөлт шалгах'}
              type={ButtonType.primary}
              onClick={check}
              isLoading={isChecking}
              className="border-none mx-auto mt-[25px] py-[20px]"
            />
            {_.isEqual(errorMessage, 'Амжилттай гүйлгээ хийгдлээ') ? (
              <p className="text-green mt-10"></p>
            ) : (
              <p className="text-red mt-10">{errorMessage}</p>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QpayInfo;
