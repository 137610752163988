import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query';

const BACKEND_URL = "http://172.16.251.251:3001"

const prisonQuery = fetchBaseQuery({
  baseUrl: BACKEND_URL,
  prepareHeaders: (headers) => {
    headers.set('Content-Type', `application/json`);
    return headers;
  },
});

export default prisonQuery;