import {createApi} from '@reduxjs/toolkit/dist/query/react';

import prisonPaymentQuery from '../helpers/prisonPaymentQuery';
import {
  PrisonPaymentResponse,
} from '../types';

export const prisonPaymentApi = createApi({
  
  reducerPath: 'prisonPaymentApi',
  baseQuery: prisonPaymentQuery,
  tagTypes: [
    'prisonPaymentApi',
  ],
  endpoints: (builder) => ({
    payLoad: builder.mutation<string, string>({
      query: (data) => ({
        url: `/requestToPos/message?data=${data}`,
      }),
      invalidatesTags: ['prisonPaymentApi'],
    }),
  }),
})

export const {
  usePayLoadMutation,
} = prisonPaymentApi;
