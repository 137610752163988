import {Spin} from 'antd';
import _ from 'lodash';
import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import image_gazar_1 from '../../assets/images/service_icons/icon_gazar_1.svg';
import Button, {ButtonType} from '../../component/Button';
import ServiceCard, {CardSize} from '../../component/ServiceCard';
import {useGetSubServicesByIdQuery} from '../../store/commonApi';
import {IdRequest} from '../../types';

const SubServices = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(9999);

  const params = useParams() as any;
  const reqHeader: IdRequest = {
    id: params.id,
    kiosk: params.kiosk,
  };
  const {data, isLoading} = useGetSubServicesByIdQuery(reqHeader);

  return (
    <div className="mt-[48px] text-center">
      <div className="mt-[106px] flex flex-wrap justify-center gap-6">
        {isLoading ? (
          <Spin />
        ) : data ? (
          data.map((d, i) => {
            return (
              <ServiceCard
                key={`key_${i}`}
                text={d.service.name}
                description={d.service.short_description}
                src={
                  !_.isEmpty(d.service.icon) &&
                  !_.isEmpty(d.service.icon_active)
                    ? selectedId == i
                      ? d.service.icon_active
                      : d.service.icon
                    : image_gazar_1
                }
                onClick={() => {
                  setSelectedId(i);
                }}
                size={CardSize.big}
                isActive={selectedId == i ? true : false}
              />
            );
          })
        ) : (
          <p className="text-lg">Одоогоор бүртгэлтэй үйлчилгээ байхгүй байна</p>
        )}
      </div>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />

        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            if (selectedId != 9999 && data) {
              if (_.includes(location.pathname, 'prison')) {
                data[selectedId].service.request_type_name == 'COMPLAINCE'
                  ? navigate(
                      `/prison/complaintDetail/${data[selectedId].id}/${data[selectedId].service.id}`,
                    )
                  : navigate(
                      `/prison/applications/${data[selectedId].id}/${data[selectedId].service.id}`,
                    );
              } else {
                data[selectedId].service.request_type_name == 'COMPLAINCE'
                  ? navigate(
                      `/complaintDetail/${data[selectedId].id}/${data[selectedId].service.id}`,
                    )
                  : navigate(
                      `/applications/${data[selectedId].id}/${data[selectedId].service.id}`,
                    );
              }
            }
          }}
          type={ButtonType.primary}
          isActive={false}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>
    </div>
  );
};

export default SubServices;
