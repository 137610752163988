import {useDispatch, useSelector} from 'react-redux';
import {Navigate, useNavigate} from 'react-router-dom';

import {PictureControls} from './PictureControls';
import Button, {ButtonType} from '../../component/Button';
import {
  getScreenshot,
  loginUser,
  setAuthError,
} from '../../features/auth/authSlice';

export const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenshot = useSelector(getScreenshot);

  const manageForm = (e) => {
    e.preventDefault();
    dispatch(setAuthError({login: {serverErr: null}}));
    validateInputs();
    if (screenshot != null) {
      const user = {screenshot};
      console.log(user);
      dispatch(loginUser(user)).then((payload) => {
        if (payload.meta.requestStatus === 'fulfilled') {
          console.log(payload);
          navigate(`/prison/1`);
        }
      });
  
    }
  };

  const validateInputs = () => {
    if (screenshot == null) {
      dispatch(
        setAuthError({
          login: {screenshot: 'Камераа асааж зургаа баталгаажуулна уу?'},
        }),
      );
    } else {
      dispatch(setAuthError({login: {screenshot: null}}));
    }
  };

  return (
    <form className="mx-5">
      <p className="text-lg mt-4">
        Та камерагаа идэвхижүүлж <br /> зургаа баталгаажуулсаны дараа <br />{' '}
        нэвтэрч орно уу?
      </p>
      <div className="flex flex-col justify-center gap-6">
        <PictureControls />
        <Button
          text={'Нэвтрэх'}
          onClick={(e) => manageForm(e)}
          type={ButtonType.primary}
          isActive={false}
          isLoading={false}
        />
      </div>
    </form>
  );
};
