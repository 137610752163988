import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query';

const xypQuery = fetchBaseQuery({
  baseUrl: 'https://auth.mkhutuch.mn/',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', `application/json`);
    return headers;
  },
});

export default xypQuery;
