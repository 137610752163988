import {FetchBaseQueryError} from '@reduxjs/toolkit/dist/query/react';
import {Rule} from 'antd/es/form';

import {ApiErr} from '../types';

export const layout = {
  default: [
    '~ 1 2 3 4 5 6 7 8 9 0 Е Щ {bksp}',
    '{tab} Ф Ц У Ж Э Н Г Ш Ү З К Ъ |',
    '{lock} Й Ы Б Ө А Х Р О Л Д П {enter}',
    '{shift} Я Ч Ё С М И Т Ь В Ю {shift}',
    '.com @ {space}',
  ],
  shift: [
    '~ 1 2 3 4 5 6 7 8 9 0 Е Щ {bksp}',
    '{tab} Ф Ц У Ж Э Н Г Ш Ү З К Ъ |',
    '{lock} Й Ы Б Ө А Х Р О Л Д П {enter}',
    '{shift} Я Ч Ё С М И Т Ь В Ю {shift}',
    '.com @ {space}',
  ],
};

export function checkRegNum(value: string) {
  let validated = true
  if(value.length != 10) validated = false
  return validated
}

export function sortRows(rows: Array<any>, key: string, asc = true) {
  if (asc) {
    return rows.sort((a: any, b: any) => {
      return a[key].toString().localeCompare(b[key].toString(), 'ja') >= 0
        ? 1
        : -1;
    });
  } else {
    return rows.sort((a: any, b: any) => {
      return b[key].toString().localeCompare(a[key].toString(), 'ja') >= 0
        ? 1
        : -1;
    });
  }
}

export async function sortRowsWithJSX(
  rows: (string | JSX.Element)[][],
  index = 0,
  ask = true,
) {
  if (ask) {
    return rows.sort((a: any, b: any) => {
      return a[index].localeCompare(b[index], 'ja');
    });
  } else {
    return rows.sort((a: any, b: any) => {
      return b[index].localeCompare(a[index], 'ja');
    });
  }
}

export function selectFile(
  isImg: boolean,
): Promise<{tmp: string; file: string}> {
  return new Promise<{tmp: string; file: string}>((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    if (isImg) {
      input.setAttribute('accept', 'image/*');
    } else {
      input.setAttribute('accept', 'application/pdf');
    }
    input.click();
    input.onchange = (e: any) => {
      if (isImg) {
        const tmpPath = URL.createObjectURL(e.target.files[0]);
        if (e.target.files[0].type.includes('image')) {
          resolve({tmp: tmpPath, file: e.target.files[0]});
        } else {
          reject('You could only upload image.');
        }
      } else {
        if (e.target.files[0].type.includes('pdf')) {
          resolve({
            tmp: e.target.files[0].name,
            file: e.target.files[0],
          });
        } else {
          reject('You could only upload pdf file.');
        }
      }
    };
  });
}

export const formatError = (err: FetchBaseQueryError | any) => {
  switch (err.data?.error) {
    case ApiErr.accountNotExist:
      return 'このアカウントは存在しません。';
    case ApiErr.inactiveAccount:
      return 'アカウントが無効です。';
    case ApiErr.wrongPassword:
      return 'パスワードが間違っています。';
    default:
      return 'リフレッシュして最初からやり直してください。';
  }
};

export const formatCurrency = (value?: number, suffix = '₮') => {
  return !value
    ? ''
    : `${new Intl.NumberFormat('en-US').format(value)}${suffix}`;
};

export const getEnumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

export const requireFormField = (message: string): Rule => {
  return {
    required: true,
    message: message,
  };
};

export const validatePassword = (isCreate: boolean): Rule => {
  return {
    required: isCreate,
    message:
      'パスワードは、1〜9の数字1つ、小文字1つ、大文字1つ、特殊文字1つを含み、スペースを含まず、8〜16文字である必要があります。',
    pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
  };
};
