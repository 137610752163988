import {Input, Modal} from 'antd';
import _ from 'lodash';
import React, {useRef, useState} from 'react';
import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';
import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import {layout} from '../../helpers/utils';
import Button, {ButtonType} from '../Button';

const DynamicInputs = ({isModalOpen, handleOk, handleCancel, fields}) => {
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState();
  const [inputName, setInputName] = useState('default');
  const [errorMessage, setErrorMessage] = useState('');
  const keyboard = useRef(null);

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const onChangeAll = (inputs) => {
    setInputs(inputs);
  };

  const onChange = (inputs) => {
    setInputs({...inputs});
  };

  const getInputValue = (inputName) => {
    return inputs[inputName] || '';
  };

  const validate = () => {
    let isVerified = true;
    fields.map((el) => {
      if (_.isEmpty(getInputValue(`${el.field_name}`))) isVerified = false;
    });
    if (!isVerified) setErrorMessage('Бүрэн бөглөнө үү');
    else {
      let temp = [];
      fields.map((el) => {
        temp.push({
          field_name: el.field_name,
          value: getInputValue(`${el.field_name}`),
        });
      });
      handleOk(temp);
    }
    return isVerified;
  };

  return (
    <div>
      <Modal
        className="bg-transparent min-w-full min-h-screen p-0 top-0"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={1}
            text={'Буцах'}
            type={ButtonType.bordered}
            onClick={handleCancel}
            isLoading={false}
            leftImage={icon_button_left}
          />,
          <Button
            key={2}
            text={'Бөглөх'}
            type={ButtonType.primary}
            onClick={() => {
              setErrorMessage('');
              validate();
            }}
            isLoading={false}
            rightImage={icon_button_right}
          />,
        ]}
      >
        <div className="text-white bg-background max-w-[1200px] min-h-[400px]">
          <h5 className="text-md font-bold">
            Хүсэлт гаргагчийн зайлшгүй бөглөх мэдээллүүд
          </h5>
          <div className="grid grid-cols-3 pt-[30px] gap-6">
            {fields.map((el, inx) => (
              <div key={`dform_${inx}`}>
                <p className="text-sm text-green mb-3">{el.placeholder}</p>
                <Input
                  value={getInputValue(`${el.field_name}`)}
                  onFocus={() => setInputName(`${el.field_name}`)}
                  onChange={onChangeInput}
                  placeholder={el.placeholder}
                  className="h-[70px] bg-gray text-white"
                ></Input>
              </div>
            ))}
          </div>
          {!_.isEmpty(errorMessage) && (
            <p className="text-red mt-[40px]">{errorMessage}</p>
          )}
        </div>
        <div className="absolute left-0 bottom-0 h-[425px] w-screen bg-background">
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            onChange={(e) => onChange(e)}
            onChangeAll={onChangeAll}
            inputName={inputName}
            layout={layout}
          ></Keyboard>
        </div>
      </Modal>
    </div>
  );
};

export default DynamicInputs;
