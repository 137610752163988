/* eslint-disable import/order */
import { useDispatch, useSelector } from "react-redux"
import Webcam from "react-webcam"

import { getActiveTab, getCameraStatus, getIsFlashing, setCameraStatus, setAuthError, setIsFlashing, setScreenshot } from "../../features/auth/authSlice"

import '../css/Camera.css'
import { useEffect, useRef } from "react"

import { Preview } from "./Preview"


import { getFacenetMessage, getOutline, setFacenetMessage, setOutline } from "../../features/auth/facenetSlice"

export const Camera = () => {

    const dispatch = useDispatch()
    const isFlashing = useSelector(getIsFlashing)
    const cameraStatus = useSelector(getCameraStatus)
    const activeTab = useSelector(getActiveTab)
    const message = useSelector(getFacenetMessage)
    const outline = useSelector(getOutline)
    const webcamRef = useRef()
    const containerRef = useRef()
    const detection = useRef()

    const takeScreenshot = () => {
        dispatch(setCameraStatus('closed'))
        dispatch(setScreenshot(webcamRef.current.getScreenshot()))
        dispatch(setIsFlashing(true))
    }

    const handleCameraError = () => {
        dispatch(setCameraStatus('closed'))
        const err = {}
        err[activeTab] = { serverErr: 'There was a problem accessing the WEBCAM. Grant permission and reload the page.' }
        dispatch(setAuthError(err))
    }


    const handleStreamVideo = async (e) => {
        const err = {}
        err[activeTab] = { serverErr: null }
        dispatch(setAuthError(err))
        let counter = 5
        detection.current = setInterval(async () => {
            if (counter != 0) {
                counter--
                dispatch(setFacenetMessage('Хөдөлгөөнгүй Камер руу харна уу ' + counter + ' seconds.'))
            } else {
                takeScreenshot()
            }
        }, 1000)
    }

    useEffect(() => {
        return () => {
            clearInterval(detection.current)
        }
    }, [cameraStatus])


    return (
        <div className="camera-container" style={{ backgroundImage: 'url("/images/camera.jpg")' }} ref={containerRef}>
            {(cameraStatus === 'opened') &&
                <>
                    <Webcam
                        className="camera-video"
                        id="webcam"
                        ref={webcamRef}
                        screenshotFormat='image/jpeg'
                        screenshotQuality={1}
                        width={570}
                        height={700}
                        mirrored={true}
                        videoConstraints={{ facingMode: 'user' }}
                        onUserMedia={(e) => handleStreamVideo(e)}
                        onUserMediaError={handleCameraError}
                    />
                    <div className="camera-face-overlay" style={{ borderColor: outline }}>
                        <div className="camera-face-message">{message}</div>
                    </div>
                </>
            }
            <Preview containerRef={containerRef} />
            <div
                className="camera-flash"
                style={{
                    animation: isFlashing && 'flashAnimation 750ms ease-out',
                }}
                onAnimationEnd={() => dispatch(setIsFlashing(false))}
            />
        </div>
    )
}