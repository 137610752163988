export enum CardSize {
  small,
  big,
}

interface CardProps {
  id?: string;
  text: string;
  description: string;
  src: string;
  onClick: () => void;
  size: CardSize;
  className?: string;
  isActive: boolean;
}

const ServiceCard: React.FC<CardProps> = ({
  id,
  text,
  description,
  src,
  onClick,
  size,
  className,
  isActive,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${
        size == CardSize.small ? 'h-[244px] w-[280px]' : 'h-[504px] w-[504px]'
      } rounded-md  ${
        isActive ? 'bg-green' : 'bg-gray'
      } p-[37px] flex flex-col items-center justify-center cursor-pointer ${className}`}
    >
      <img
        className={`${
          size == CardSize.small ? 'h-[88px] w-[88px]' : 'h-[100px] w-[125px]'
        }`}
        src={src.length >= 100 ? `data:image/svg+xml;base64,${src}` : src}
      ></img>
      <h3
        className={`${
          size == CardSize.small ? 'text-22 mt-[16px]' : 'text-xl mt-[32px]'
        }`}
      >
        {text}
      </h3>
      <p
        className={`${
          size == CardSize.small ? 'text-xs mt-[32px]' : 'text-sm mt-[16px]'
        } font-light break-words`}
      >
        {description}
      </p>
    </div>
  );
};

export default ServiceCard;
