import {createApi} from '@reduxjs/toolkit/dist/query/react';

import baseQuery from '../helpers/baseQuery';
import {
  ALL_SERVICES,
  SUB_SERVICES,
  SERVICE_DETAIL,
  CREATE_REQUEST,
  REQUEST_DETAIL,
  CREATE_QPAY_INVOICE,
  CHECK_QPAY_INVOICE,
} from '../helpers/url_helper';
import {
  CustomForm,
  IdRequest,
  KioskService,
  QPayCheckRequest,
  QPayCheckResponse,
  QPayCreationRequest,
  QPayCreationResponse,
  RequestCreationRequest,
  RequestCreationResponse,
  RequestDetailResponse,
  ServiceCategory,
} from '../types';

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: baseQuery,
  tagTypes: ['Service', 'IdRequest', 'Request', 'RequestDetail', 'QPAY'],
  endpoints: (builder) => ({
    getSubServiceCategoriesById: builder.query<ServiceCategory[], string>({
      query: (id) => ({
        url: `${ALL_SERVICES}?typeId=${id}`,
      }),
      providesTags: ['Service'],
    }),
    getSubServicesById: builder.query<KioskService[], IdRequest>({
      query: (d) => ({
        url: `${SUB_SERVICES}/${d.kiosk}/${d.id}`,
      }),
      providesTags: ['Service'],
    }),
    getServiceDetailsById: builder.query<CustomForm, string>({
      query: (id) => ({
        url: `${SERVICE_DETAIL}/${id}`,
      }),
      providesTags: ['Service'],
    }),
    createRequestById: builder.mutation<
      RequestCreationResponse,
      RequestCreationRequest
    >({
      query: (body: RequestCreationRequest) => ({
        url: `${CREATE_REQUEST}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Request'],
    }),
    getRequestDetailsById: builder.query<RequestDetailResponse, string>({
      query: (id) => ({
        url: `${REQUEST_DETAIL}/${id}`,
      }),
      providesTags: ['RequestDetail'],
    }),
    createQPayById: builder.mutation<QPayCreationResponse, QPayCreationRequest>(
      {
        query: (body: QPayCreationRequest) => ({
          url: `${CREATE_QPAY_INVOICE}`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: ['QPAY'],
      },
    ),
    checkQPayByInvoiceId: builder.mutation<QPayCheckResponse, QPayCheckRequest>(
      {
        query: (body: QPayCheckRequest) => ({
          url: `${CHECK_QPAY_INVOICE}`,
          method: 'POST',
          body: body,
        }),
        invalidatesTags: ['QPAY'],
      },
    ),
  }),
});

export const {
  useGetSubServicesByIdQuery,
  useGetSubServiceCategoriesByIdQuery,
  useGetServiceDetailsByIdQuery,
  useCreateRequestByIdMutation,
  useGetRequestDetailsByIdQuery,
  useCreateQPayByIdMutation,
  useCheckQPayByInvoiceIdMutation,
} = commonApi;
