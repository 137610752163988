import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import icon_button_right from '../../assets/images/icon_button_right.svg';
import image_huvi from '../../assets/images/icon_huvi.svg';
import image_huvi_active from '../../assets/images/icon_huvi_active.svg';
import image_tur from '../../assets/images/icon_tur.svg';
import image_tur_active from '../../assets/images/icon_tur_active.svg';
import Button, {ButtonType} from '../../component/Button';
import ServiceCard, {CardSize} from '../../component/ServiceCard';

interface ServiceMockData {
  title: string;
  description: string;
  url: string;
  active_url: string;
}
declare global {
  interface Window {
    electron?: any;
  }
}

const HomePage = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState(9999);

  const params = useParams() as any;

  useEffect(() => {
    localStorage.setItem('kiosk', params.id);
  }, []);

  const data: ServiceMockData[] = [
    {
      title: 'Төрийн үйлчилгээ',
      description: 'Та хувь хүний үйлчилгээ, тодорхойлолт авна',
      url: image_tur,
      active_url: image_tur_active,
    },
    {
      title: 'Тодорхойлолт',
      description: 'Та байгууллагын үйлчилгээ, тодорхойлолт авна',
      url: image_huvi,
      active_url: image_huvi_active,
    },
  ];
 
  return (
    
    <div className="mt-[48px] text-center">
      <p className="text-lg">Та өөрийн авах үйлчилгээний төрлөө сонгоно уу?</p>
      <div className="mt-[56px] flex flex-wrap justify-center gap-6">
        {data.map((d, i) => {
          return (
            <ServiceCard
              key={`key_${i}`}
              text={d.title}
              description={d.description}
              src={selectedId == i ? d.active_url : d.url}
              onClick={() => {
                setSelectedId(i);
              }}
              size={CardSize.big}
              isActive={selectedId == i ? true : false}
            />
          );
        })}
      </div>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-end border-t border-white">
        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            if (selectedId != 9999)
              navigate(`/services/${params.id}/${selectedId == 0 ? 2 : 3}`);
          }}
          type={ButtonType.primary}
          isActive={false}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>
    </div>
  );
};

export default HomePage;
