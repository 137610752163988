import React from 'react';

import '../../assets/styles/invoice.css';
import logo from '../../assets/images/logo_mini_pos.png';

interface InvoiceProps {
  aimag: string;
  sum: string;
  id: number;
  uniqueNum: string;
  firstname: string;
  regnum: string;
  service_name: string;
  price: number;
  bank_commision: number;
  total: number;
  className?: string;
}

const InvoiceTemplate: React.FC<InvoiceProps> = ({
  aimag,
  sum,
  id,
  uniqueNum,
  firstname,
  regnum,
  service_name,
  price,
  bank_commision,
  total,
  className,
}) => {
  const year = new Date().getFullYear();
  const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
  const day = new Date().getDay().toString().padStart(2, '0');

  return (
    <div className={`w-[300px] min-h-[977px] ${className}`}>
      <div
        className="flex mt-2 mb-4 align-center justify-center"
        style={{position: 'relative'}}
      >
        <p
          className="text-center uppercase text-xs"
          style={{marginBottom: '5px'}}
        >
          {aimag} АЙМГИЙН
          <br />
          {sum} СУМЫН УЛСЫН
          <br />
          БҮРТГЭЛИЙН ЕРӨНХИЙ ГАЗАР
          <br />
        </p>
      </div>
      <hr />
      <p className="text-center fs-small mb-2">
        {year.toString()} оны {month.toString()}
        -р сарын {day.toString()}-ны өдөр
      </p>
      <hr className="mb-2" />
      <p className="text-center fs-small mb-2">ТӨЛБӨРИЙН БАРИМТ - {id}</p>
      <p className="fs-small">Нэр: {firstname}</p>
      <p className="fs-small">Регистрийн дугаар: {regnum}</p>
      <p className="fs-small">Хүсэлтийн дугаар - {uniqueNum}</p>
      <p className="fs-small">Хүлээн авах ажилтан: {firstname}</p>
      <p className="fs-small mb-2">Регистрийн дугаар: {regnum}</p>
      <hr className="mb-4" />
      <div className="flex justify-between">
        <p className="fs-small">Лавлагаа</p>
        <p className="text-end fs-small max-w-[150px]">{service_name}</p>
      </div>
      <div className="flex justify-between">
        <p className="fs-small">Үйлчилгээний шимтгэл</p>
        <p className="fs-small">{price} ₮</p>
      </div>
      <div className="flex justify-between">
        <p className="fs-small">Банкний шимтгэл</p>
        <p className="fs-small">{bank_commision} ₮</p>
      </div>
      <div className="flex justify-between mb-4">
        <p className="fw-bold fs-small">Нийт дүн:</p>
        <p className="fs-small">{total} ₮</p>
      </div>
      <p className="text-center uppercase text-xs mt-[30px] mb-[20px]">
        мини киоскоор үйлчлүүлсэн <br /> танд баярлалаа
      </p>
      <div className="flex justify-center">
        <img src={logo} className="w-[100px] mx-auto" />
      </div>
    </div>
  );
};

export default InvoiceTemplate;
