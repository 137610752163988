import {createApi} from '@reduxjs/toolkit/dist/query/react';

import prisonQuery from '../helpers/prisonQuery';
import {
  PRISONER_DATA,
} from '../helpers/url_helper';
import {
  PrisonRequest,
  PrisonResponse,
} from '../types';

export const prisonApi = createApi({
  reducerPath: 'prisonApi',
  baseQuery: prisonQuery,
  tagTypes: [
    'prisonApi',
  ],
  endpoints: (builder) => ({
    getFullData: builder.mutation<PrisonResponse, PrisonRequest>({
      query: (body: PrisonRequest) => ({
        url: `${PRISONER_DATA}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['prisonApi'],
    }),
  }),
})

export const {
  useGetFullDataMutation,
} = prisonApi;