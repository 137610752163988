export const LOGIN_URL = '/admin/login';
export const ALL_SERVICES = '/all';
export const SUB_SERVICES = '/sub-services';
export const SERVICE_DETAIL = '/detail';
export const CREATE_REQUEST = '/create-request';
export const REQUEST_DETAIL = '/request-detail'

export const CREATE_QPAY_INVOICE = '/create-invoice'
export const CHECK_QPAY_INVOICE = '/check-payment'

export const XYP_OTP = '/api/xyp/otp';
export const XYP_VERIFY = '/api/xyp/otp/verify';

export const PRISONER_DATA = '/api/data';
export const PAYMENT_LOAD = '/';