import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {
  XYP_OTP,
  XYP_VERIFY
} from '../helpers/url_helper';
import xypQuery from '../helpers/xypQuery';
import {
  XypOtpRequest,
  XypOtpResponse,
  XypVerifyRequest
} from '../types';

export const xypApi = createApi({
  reducerPath: 'xypApi',
  baseQuery: xypQuery,
  tagTypes: [
    'XypOTP',
    'XypVerifyOTP'
  ],
  endpoints: (builder) => ({
    xypOTP: builder.mutation<XypOtpResponse, XypOtpRequest>({
      query: (body: XypOtpRequest) => ({
        url: `${XYP_OTP}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['XypOTP'],
    }),
    veryXypOTP: builder.mutation<string[], XypVerifyRequest>({
      query: (body: XypVerifyRequest) => ({
        url: `${XYP_VERIFY}`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['XypVerifyOTP'],
    }),
  }),
})

export const {
  useXypOTPMutation,
  useVeryXypOTPMutation
} = xypApi;
