import {Spin} from 'antd';
import _, {result} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import icon_info from '../../assets/images/icon_info.svg';
import Button, {ButtonType} from '../../component/Button';
import AuthStatus from '../../component/popup/AuthStatus';
import DynamicInputs from '../../component/popup/DynamicInputs';
import RegisterInputs from '../../component/popup/RegisterInputs';
import {formatCurrency} from '../../helpers/utils';
import {
  useCreateRequestByIdMutation,
  useGetServiceDetailsByIdQuery,
} from '../../store/commonApi';
import {AdditionalFormInputValues, RequestCreationRequest} from '../../types';

const ApplicationList = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const params = useParams() as any;
  const {data, isLoading} = useGetServiceDetailsByIdQuery(params.id);
  const [createRequest] = useCreateRequestByIdMutation();
  const [regnum, setRegNum] = useState('');
  const [phone, setPhoneNum] = useState('');
  const [fields, setFields] = useState<AdditionalFormInputValues[]>();
  const [endpoints, setEndPoints] = useState<string[]>();
  const [serviceUrls, setServiceUrls] = useState<string[]>();
  const [templates, setTemplates] = useState<string[]>();

  const [isCreatingRequest, setCreateRequest] = useState(false);

  useEffect(() => {
    !_.isEmpty(regnum) && setIsAuthModalOpen(true);
  }, [regnum]);

  useEffect(() => {
    if (!_.isEmpty(data?.xyp)) {
      const temp: string[] = [];
      const temp_urls: string[] = [];
      const temp_names: string[] = [];
      data?.xyp.map((val) => {
        temp.push(val.xyp.endpoint);
        temp_urls.push(val.xyp.service_url);
        temp_names.push(val.xyp.template_name);
      });
      setEndPoints(temp);
      setServiceUrls(temp_urls);
      setTemplates(temp_names);
    }
  }, [data]);

  const createReq = async (file_tokens: string[]) => {
    setCreateRequest(true);
    const requestData: RequestCreationRequest = {
      kiosk_service: params.kiosk,
      request_creator_register: regnum,
      file_tokens: file_tokens,
      request_creator_phone: phone,
      complaint_text: '',
    };
    if (!_.isEmpty(fields)) requestData.additional_json = fields;
    console.log(requestData);
    await createRequest(requestData)
      .unwrap()
      .then((response) => {
        setCreateRequest(false);
        if (_.includes(location.pathname, 'prison')) {
          navigate(`/prison/report/${response.id}`);
        } else {
          navigate(`/report/${response.id}`);
        }
      })
      .catch((e: ErrorCallback) => {
        console.log(e);
        alert('Error');
        setCreateRequest(false);
      });
  };

  return (
    <div className="mt-[48px]">
      <Spin spinning={isLoading || isCreatingRequest}>
        <div className="px-[98px] flex">
          <div className="rounded-md w-[1021px] bg-gray h-[667px] p-[57px] overflow-y-scroll">
            <h3 className="text-xl mb-[40px]">Бүрдүүлэх материалууд</h3>
            {data ? (
              <div>
                {data.xyp.map((d, i) => {
                  return (
                    <div
                      className="flex justify-between border border-white py-2"
                      key={`key_${i}`}
                    >
                      <div>
                        <h5 className="text-22">{d.xyp.name}</h5>
                      </div>
                      <div className="flex items-center">
                        <h5 className="text-green text-22">Төлбөрт багтсан</h5>
                      </div>
                    </div>
                  );
                })}

                {data.additional && (
                  <div className="flex justify-between border border-white py-2">
                    <div>
                      <h5 className="text-22">{data.additional.name}</h5>
                    </div>
                    <div className="flex items-center">
                      <h5 className="text-right text-green text-22">
                        Нэмэлтээр киоскноос оруулна
                      </h5>
                    </div>
                  </div>
                )}
                {data.custom.map((d, i) => {
                  return (
                    <div
                      className="flex justify-between border border-white py-2"
                      key={`key_${i}`}
                    >
                      <div>
                        <h5 className="text-22">{d.client_custom_form.name}</h5>
                      </div>
                      <div className="flex items-center">
                        <h5 className="text-red text-22">
                          Биечлэн авчирч өгнө
                        </h5>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-lg">
                Одоогоор бүртгэлтэй үйлчилгээ байхгүй байна
              </p>
            )}
          </div>
          <div className="flex-1 flex flex-col justify-between ml-[63px]">
            <div className="flex-1">
              <img width={120} height={120} src={icon_info}></img>
              <h3 className="text-xl mb-[40px] mt-[32px]">
                Төлбөр төлснөөр таны мэдээллүүд автоматаар ХУР системээс
                татагдах болно
              </h3>
              {data && (
                <p className="font-light text-xs mt-2">
                  {data.name}
                  <br />
                  <br />
                  Тайлбар: {data.warning_info}
                </p>
              )}
            </div>
            <div className="flex justify-between">
              {data && (
                <>
                  <h3 className="text-xl mb-[40px] mt-[32px]">Нийт үнэ:</h3>
                  <h3 className="text-green text-xl mb-[40px] mt-[32px]">
                    {isLoading
                      ? '-'
                      : data
                      ? data.total == 0
                        ? 'Үнэгүй'
                        : `${formatCurrency(data.total)}`
                      : '0₮'}
                  </h3>
                </>
              )}
            </div>
          </div>
        </div>
      </Spin>
      <div className="absolute bottom-0 w-full mt-[56px] py-[30px] px-[106px] flex justify-between border-t border-white">
        <Button
          text={'Буцах'}
          onClick={function (): void {
            navigate(-1);
          }}
          type={ButtonType.bordered}
          isActive={false}
          isLoading={false}
          leftImage={icon_button_left}
        />

        <Button
          text={'Үргэлжлүүлэх'}
          onClick={function (): void {
            if (_.isEmpty(fields) && !_.isEmpty(data?.additional))
              setIsFormModalOpen(true);
            else if (_.isEmpty(regnum)) setIsModalOpen(true);
          }}
          type={ButtonType.primary}
          isActive={isCreatingRequest ? isCreatingRequest : isLoading}
          isLoading={false}
          rightImage={icon_button_right}
        />
      </div>

      <RegisterInputs
        isModalOpen={isModalOpen}
        handleOk={() => {
          setIsModalOpen(false);
        }}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
        setPhoneNum={setPhoneNum}
        setRegNum={setRegNum}
      ></RegisterInputs>

      <AuthStatus
        isModalOpen={isAuthModalOpen}
        handleOk={(data: string[]) => {
          setIsAuthModalOpen(false);
          createReq(data);
        }}
        handleCancel={() => {
          setRegNum('');
          setIsAuthModalOpen(false);
        }}
        regnum={regnum}
        endpoints={endpoints}
        urls={serviceUrls}
        names={templates}
      ></AuthStatus>

      {data?.additional && (
        <DynamicInputs
          isModalOpen={isFormModalOpen}
          handleOk={(data: AdditionalFormInputValues[]) => {
            setIsFormModalOpen(false);
            console.log(data);
            setFields(data);
            setIsModalOpen(true);
          }}
          handleCancel={() => {
            setIsFormModalOpen(false);
          }}
          fields={data?.additional.response_data}
        ></DynamicInputs>
      )}
    </div>
  );
};

export default ApplicationList;
