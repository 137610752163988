import { Input, Modal } from 'antd';
import React, { useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';
import icon_button_left from '../../assets/images/icon_button_left.svg';
import icon_button_right from '../../assets/images/icon_button_right.svg';
import { checkRegNum } from '../../helpers/utils';
import { layout } from '../../helpers/utils';
import Button, { ButtonType } from '../Button';

// eslint-disable-next-line import/order
import _ from 'lodash';

const RegisterInputs = ({ isModalOpen, handleOk, handleCancel, setRegNum, setPhoneNum }) => {
  const [inputs, setInputs] = useState({});
  const [inputName, setInputName] = useState('default');
  const [errorMessage, setErrorMessage] = useState('');
  const keyboard = useRef(null);
  const regnum = useRef(null);

  const onChangeInput = (event) => {
    const inputVal = event.target.value;
    setInputs({
      ...inputs,
      [inputName]: inputVal,
    });

    keyboard.current.setInput(inputVal);
  };

  const onChangeAll = (inputs) => {
    setInputs(inputs);
  };

  const onChange = (inputs) => {
    setInputs({ ...inputs });
  };

  const getInputValue = (inputName) => {
    return inputs[inputName] || '';
  };

  return (
    <div>
      <Modal
        className="bg-transparent min-w-full min-h-screen p-0 top-0"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            key={1}
            text={'Буцах'}
            type={ButtonType.bordered}
            onClick={handleCancel}
            isLoading={false}
            leftImage={icon_button_left}
          />,
          <Button
            key={2}
            text={'Бөглөх'}
            type={ButtonType.primary}
            onClick={() => {
              if (checkRegNum(getInputValue('regnum'))) {
                handleOk();
                setErrorMessage('')
                setPhoneNum(getInputValue('phone'))
                setRegNum(getInputValue('regnum'));
              } else {
                setErrorMessage("Регистрийн дугаараа зөв оруулна уу")
              }
            }}
            isLoading={false}
            rightImage={icon_button_right}
          />,
        ]}
      >
        <div className="text-white bg-background max-w-[1200px] min-h-[400px] text-center my-auto">
          <div className="max-w-[700px] mx-auto">
            <h5 className="max-w-[400px] mx-auto text-md font-bold">
              Регистрийн дугаараа оруулна уу ?
            </h5>
            <div className='flex gap-4'>
              <div className="flex-1 pt-[30px] text-start mt-[30px]">
                <p className="text-sm text-green mb-3">Регистрийн дугаар</p>
                <Input
                  ref={regnum}
                  value={getInputValue('regnum')}
                  onFocus={() => setInputName('regnum')}
                  onChange={onChangeInput}
                  placeholder="Регистрийн дугаар"
                  className="h-[70px] bg-gray text-white"
                ></Input>
              </div>
              <div className="flex-1 pt-[30px] text-start mt-[30px]">
                <p className="text-sm text-green mb-3">Утасны дугаар</p>
                <Input
                  value={getInputValue('phone')}
                  onFocus={() => setInputName('phone')}
                  onChange={onChangeInput}
                  placeholder="Утасны дугаар"
                  className="h-[70px] bg-gray text-white"
                ></Input>
              </div>
            </div>
            {
              !_.isEmpty(errorMessage) && <p className='text-red mt-[40px]'>
                {errorMessage}
              </p>
            }
          </div>
        </div>
        <div className="absolute left-0 bottom-0 h-[425px] w-screen bg-background">
          <Keyboard
            keyboardRef={(r) => (keyboard.current = r)}
            onChange={(e) => onChange(e)}
            onChangeAll={onChangeAll}
            inputName={inputName}
            layout={layout}
          ></Keyboard>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterInputs;
